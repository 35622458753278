import React, {useEffect} from "react";
import {useHistory, useMessages} from '../util/react-util.js';

let base = '/lti';

function isNotLinkPushEvent(event) {
    return (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
            || event.defaultPrevented || event.button !== 0);
}

function Link(props) {
    let history = useHistory();
    let currentPage = history.location.pathname === props.to; // hash
    return (
        <a className="nav-link" aria-current={currentPage ? 'page' : null}
                href={props.to}
                onClick={function(event) {
                    if (isNotLinkPushEvent(event)) return;
                    event.preventDefault();
                    history.push(props.to);
                }}>
            {props.children}
        </a>);
}

function getNavigationItems(result = {}, i18n) {
    var navItems = [], m = i18n;
    result.schemaContext && navItems.push({to: base+'/edit', title: m('navEditSchema')});
    result.adminSchema && navItems.push({to: base+'/viewAdmin', href: result.adminSchema, title: m('navViewAdminSchema')});
    result.courseSchema && navItems.push({to: base+'/viewCourse', href: result.courseSchema, title: m('navViewCourseSchema')});
    result.studentSchemas && navItems.push({to: base+'/viewStudent', href: result.studentSchemas, title: m('navViewStudentSchema')});
    result.debug && navItems.push({to: base+'/debug', title: 'Debug' });
    return navItems;
}

export default function Navigation(props) {
    var // base = props.base,
        result = props.result,
        history = useHistory(),
        navItems = getNavigationItems(result, useMessages());
    useEffect(function() {
        var locations = navItems.map(function(navItem) {return navItem.to;});
        if (!locations.includes(history.location.pathname)) {
            navItems[0] && history.push(navItems[0].to);
        }
    }, [history, navItems]);
    if (navItems.length === 0 || !props.result) return null;
    return (
        <nav className="navbar-expand mb-5" aria-label="Sidans meny">
            <div className="collapse navbar-collapse">
                <ul className='nav flex-column'>
                    {navItems.map(function(item, idx) {
                        return (
                            <li key={idx}> {/* className='nav-link' */}
                                {item.href ? 
                                    <a href={item.href} target='_blank' className='nav-link extern'>{item.title}</a> :
                                    <Link to={item.to}>{item.title}</Link>}
                            </li>);
                    })}
                </ul>
            </div>
        </nav>);
}
