import React from "react";

/**
 * Component for the header (logo and top border) of a page.
 */
export default function PageHeader({i18n, lti}) {
    return (
        <header className="no-print layout-header layout-header-desktop">
            <div className="skip-links">
                <a href="#MainContent" tabIndex="1" className="button">Hoppa till huvudinnehållet</a>
            </div>
            <div className="no-print container">
                <div className="row align-items-center">
                    <div className="col-3 layout-header-logo">
                        <a href="https://www.uu.se" className="layout-logo logo-link">
                            <img alt="Uppsala universitet" src="https://static.uu.se/designsystem/latest/theme-uu/img/uu-logo-red.svg"/>
                        </a>
                    </div>
                    <div className="col layout-header-name">
                        <span className="layout-header-name-text">{i18n('header')}</span>
                    </div>
                    <div className="col layout-header-buttons layout-header-fixed-mobile local-nav-buttons">
                    </div>
                </div>
            </div>
        </header>
    )
}
