import React from "react";
import {Info} from "./Messages";

export function DebugRoles({result: {debug}}) {
    if (!debug) return null;
    return (
        <div>
            <h2>Schema-adminroller</h2>
            <p>
                Följande Canvas-roller anses vara schema admin roller = 
                {debug.canvasSchemaRoles.map(function(r, i) { // replace(/,/g, ', ')
                    return <span>{i > 0 && <span>,</span>} {<code>{r}</code>}</span>;
                })}
            </p>
            <h2>Användarroller</h2>
            <p>
                Dina LTI-roller skickade från Studium = 
                {debug.ltiRoles.map(function(r, i) { // replace(/,/g, ', ')
                    return <span>{i > 0 && <span>,</span>} {<code>{r}</code>}</span>;
                })}
                <br/>
                Dina Canvas-roller skickade från Studium = 
                {(debug.canvasRoles||'').split(',').map(function(r, i) { // replace(/,/g, ', ')
                    return <span>{i > 0 && <span>,</span>} {<code>{r}</code>}</span>;
                })}
                <br/>
                Dina roller tilldelade i applikationen = 
                {[ 
                    debug.isParticipant && 'Participant', 
                    debug.isAdmin && 'Admin', 
                    debug.isTeacherOrTa && 'TeacherOrTa'
                ]
                 .filter(function(r) { return r; })
                 .map(function(r, i) {
                     return <span>{i > 0 && <span>,</span>} {<code>{r}</code>}</span>;
                 })}
            </p>
    </div>);
}

export function DebugParticipations(props) {
    if (!props.debug) return null;
    var d = props.debug||{}, es = d.enrollments||[], gs = d.groups||[], cs = d.courses||{}, u = d.user, db = d.db, 
        terms = d.terms.map(t=>t.name).join(', ');
    return (
        <div>
            <h2>Datakälla och deltagande</h2>
            <Info>
                Just nu används&nbsp;
                {db ? <span><strong>Canvas exporterade databas</strong>, (dvs inte REST-API:et)</span>
                    : <span><strong>Canvas REST-API</strong> (dvs inte den exporterade databasen)</span>}.
            </Info>
            <div>
                <a href={'https://uppsala.test.instructure.com/accounts/1/users/' + u}>Ditt</a>
                &nbsp;kurstillfälles- och gruppdeltagande i Studium enligt datakällan ovan för aktuella terminer ({terms}):
            </div>
            <ul>{es.map(e => <li>
                    <a href={'https://uppsala.test.instructure.com/courses/' + e.course_id}>
                        <span>{cs[e.course_id].name}</span>,&nbsp;
                        <i>{cs[e.course_id].course_code}</i>&nbsp;
                        <b>[{(cs[e.course_id].term||{}).name}]</b>
                    </a>
                    <ul>{gs.filter(g => g.course_id === e.course_id).map(g =>
                        <li>{g.name}</li>
                    )}</ul>
                </li>)}
            </ul>
        </div>);
}

export default function Debug(props) {
    return (
        <div>
            <DebugRoles result={props.result}/>
            <DebugParticipations debug={props.result.debug}/>
        </div>);
}
