import React, {useEffect, useState} from 'react';
import {useMessages, useHistory, useXhr} from './util/react-util.js';
import messages from './i18n/messages.js';
import {MessageError, MessageLoading} from "./components/Messages";
import PageWrapper from "./components/PageWrapper";
import SchemaContextAndGroupsInput from "./components/Schema";
import Debug from "./components/Debug";

export default function App() {
    var repo = useXhr(),
        [viewModel, setViewModel] = useState(),
        [schemaContext, setSchemaContext] = useState({}),
        [countSelectedInCategories, setCountSelectedInCategories] = useState({}),  // number of selected groups in each group category
        i18n = useMessages(messages, "sv"),
        history = useHistory(),
        editSchema = history.location.pathname.match(/\/edit$/),
        viewAdminSchema = false && history.location.pathname.match(/\/viewAdmin$/),
        viewCourseSchema = false && history.location.pathname.match(/\/viewCourse$/),
        viewStudentSchemas = false && history.location.pathname.match(/\/viewStudent$/),
        debug = history.location.pathname.match(/\/debug$/);
    function loadData() {
        repo.get('/lti/viewModel', {}, function(r) {
            i18n({messages: messages, locale: r.locale});
            setViewModel(r);
            r.schemaContext && setSchemaContext(r.schemaContext);
            if (r.schemaContext) {
                var startCountSelectedInCategories = {};
                r.schemaContext.groupCategories.map(category => {
                    var selectedInGroup = 0;
                    r.schemaContext.schemaGroups.map(group => {
                        console.log('comp: group.category=' + group.category + ', category.id=' + category.id + ', group.schema=' + group.schema);
                        if (group.groupCategoryId === category.id && group.schema) {
                            selectedInGroup++;
                        }
                    });
                    startCountSelectedInCategories[category.id] = selectedInGroup;
                })
                setCountSelectedInCategories(startCountSelectedInCategories);
                console.log('start ', startCountSelectedInCategories)
            }
        });
    }
    useEffect(loadData, []);

    return (
        <>
            { repo.waiting && <MessageLoading>{i18n('loading')}</MessageLoading> }
            { repo.error && repo.status !== 403 && <MessageError>{i18n('error')}</MessageError> }
            { repo.error && repo.status === 403 && <MessageError>{i18n('errorUnauthorized')}</MessageError> }
            { viewModel &&
                <PageWrapper i18n={i18n} canvasHost={viewModel.canvasHost} version={viewModel.version} lti={viewModel.lti} viewModel={viewModel} >
                    {editSchema && <SchemaContextAndGroupsInput schemaContext={[schemaContext, setSchemaContext]}
                                                                countSelectedInCategories={[countSelectedInCategories, setCountSelectedInCategories]}
                                                                onSave={loadData} />}
                    {viewAdminSchema && <div><a href={viewModel.adminSchema}>{i18n('linkAdminSchema')}</a></div>}
                    {viewCourseSchema && <div><a href={viewModel.courseSchema}>{i18n('linkCourseSchema')}</a></div>}
                    {viewStudentSchemas && <div><a href={viewModel.studentSchemas}>{i18n('linkStudentSchema')}</a></div>}
                    {!(viewModel.schemaContext || viewModel.courseSchema || viewModel.adminSchema || viewModel.studentSchemas)
                        && <em>{i18n('neitherViewNorEditSchema')}</em>}
                    {debug && <Debug result={viewModel}/>}
              </PageWrapper>
            }
        </>
    );
}
