import React from "react";

/**
 * Components for displaying general messages such as loading info and errors.
 */
export function MessageLoading(props) {
    return (props.cond === undefined || props.cond) &&
        <div className={"alert-container"}>
            <div role={"alert"} className={"alert-box alert alert-loading"}>
                {props.children}
            </div>
        </div>
}
export function MessageInfo(props) {
    return (props.cond === undefined || props.cond) &&
        <div className={"alert-container"}>
            <div role={"alert"} className={"alert-box alert alert-info"}>
                {props.children}
            </div>
        </div>
}
export function MessageSuccess(props) {
    return (props.cond === undefined || props.cond) &&
        <div className={"alert-container"}>
            <div role={"alert"} className={"alert-box alert alert-success"}>
                {props.children}
            </div>
        </div>
}

export function MessageError(props) {
    return (props.cond === undefined || props.cond) &&
        <div className={"alert-container"}>
            <div role={"alert"} className={"alert-box alert alert-danger"}>
                {props.children}
            </div>
        </div>
}

export function MessageErrorInline(props) {
    return (props.cond === undefined || props.cond) &&
        <span>
            <span>
            </span>
            <span role={"alert"} className={"alert-danger alert-danger-inline"}>
                <em>{props.children}</em>
            </span>
            <span>
            </span>
        </span>
}

export function Success(props) {
    return (props.cond === undefined || props.cond) && (
        <div role='alert' className='alert-box alert alert-success'>
            {props.children}
        </div>);
}

export function Info(props) {
    return (props.cond === undefined || props.cond) && (
        <div role='alert' className='alert-box alert alert-info'>
            {props.children}
        </div>);
}

export function Error(props) {
    return (props.cond === undefined || props.cond) && (
        <div role='alert' className='alert-box alert alert-danger'>
            {props.children}
        </div>);
}

export function ErrorInline(props) {
    return (props.cond === undefined || props.cond) && (
        <>
            &nbsp;
            <span role='alert' className='alert-danger alert-danger-inline'>
                <em>{props.children}</em>
            </span>
            &nbsp;
        </>);
}
