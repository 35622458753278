import React from "react";
import {useMessages} from "../util/react-util";

/**
 * Component for the help modal
 */
export default function HelpModal() {
    var m = useMessages();
    return (
        <div className="modal fade" id="helpModal" tabIndex="-1" aria-labelledby="Help modal"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    {/*<div className="modal-header"></div>*/}
                    <div className="modal-body">{m('helpText')}</div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline" data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
