/**
 * I18N messages.
 */
const messages = {
    "sv": {
        "loading": "Laddar...",
        "error": "Något gick tyvärr fel",
        "errorUnauthorized": "Du är inte behörig att utföra åtgärden",
        "header": "TimeEdit Schema",
        "preamble": "TimeEdit Schema för",
        "linkAdminSchema": "Administratörschema",
        "linkCourseSchema": "Kursens schema",
        "linkStudentSchema": "Schema för dina aktuella kurser i Studium",
        "navCrumbSchedule": "Schema",
        "navEditSchema": "Administrera schema",
        "navViewAdminSchema": "Visa mitt TE-schema",
        "navViewCourseSchema": "Visa kursens schema",
        "navViewStudentSchema": "Visa schema för dina aktuella kurser i Studium",
        "neitherViewNorEditSchema": "Du är inte behörig att redigera eller visa TimeEdit schema för denna kurs",
        "noAccountId": "För att kunna schemalägga kursen i TimeEdit behöver den tillhöra en institution i Studium!",
        "noTerm": "För att kunna schemalägga kursen i TimeEdit behöver den tillhöra en termin i Studium!",
        "save": "Spara",
        "saved": "Sparat!",
        "saving": "Sparar...",
        "scheduleSections" : "Schemalägg på sektioner",
        "scheduledSectionsLegend": "Sektioner",
        "scheduledSectionsText": "Välj vilka sektioner som ska kunna schemaläggas i TimeEdit:",
        "scheduledSectionsInfo": "OBS! Nedanstående val är enbart för schemaläggning av sektioner i TimeEdit och ska endast bockas i eller ur av kursadministratörer. Sektioner i kursen återfinns under Inställningar -> Sektioner.",
        "scheduledGroupsHeader": "Schemagrundande grupper",
        "scheduleGroups" : "Schemalägg på grupper",
        "scheduledGroupsLegend": "Grupper",
        "scheduledGroupsText": "Välj vilka grupper som ska kunna schemaläggas i TimeEdit:",
        "scheduledGroupsInfo": "OBS! Nedanstående val är enbart för schemaläggning av grupper i TimeEdit och ska endast bockas i eller ur av kursadministratörer. Grupper i kursen återfinns under Personer.",
        "groupCategories": "Gruppuppsättningar",
        "schemaContextHeader": "Schemagrundande kurs",
        "schemaContextLabel": "Ja, schemalägg i TimeEdit",
        "schemaContextCourseIsScheduledInTimeEdit": "Denna kurs schemaläggs i TimeEdit.",
        "schemaContextText1": "Denna kurs schemaläggs",
        "schemaContextText2": "för närvarande inte",
        "schemaContextText3": "i TimeEdit.",
        "schemaContextText": "Vill du schemalägga denna kurs i TimeEdit?",
        "groupMissingInCanvas": "Gruppen saknas i Studium",
        "sectionMissingInCanvas": "Sektionen saknas i Studium",
        "selectAll": "Välj alla",
        "show.help": "Hjälp",
        "helpText": "Bocka i de grupper och/eller sektioner som du vill schemalägga på i TimeEdit. Tänk på att det kan ta någon timme innan grupperna/sektionerna har förts över till TimeEdit. För att kunna särskilja grupper/sektioner i TimeEdit var noga med att namnet inte är samma på dessa."
    },
    "en": {
        "loading": "Loading...",
        "error": "Something unexpected occured",
        "errorUnauthorized": "You are not authorized to perform this request",
        "header": "TimeEdit Schedule",
        "preamble": "TimeEdit Schedule for",
        "linkAdminSchema": "Administrator Schedule",
        "linkCourseSchema": "Course Schedule",
        "linkStudentSchema": "Schedules for your current courses in Studium",
        "navCrumbSchedule": "Schedule",
        "navEditSchema": "Manage schedule",
        "navViewAdminSchema": "Show my TE schedule",
        "navViewCourseSchema": "Show course schedule",
        "navViewStudentSchema": "Show schedules for your current courses in Studium",
        "neitherViewNorEditSchema": "You're neither authorized to see nor edit the TimeEdit schema of this course",
        "noAccountId": "In order to administer the schedule of this course in TimeEdit it needs to associated with a department (account) in Studium!",
        "noTerm": "In order to administer the schedule of this course in TimeEdit it needs to associated with a term in Studium!",
        "save": "Save",
        "saved": "Saved!",
        "saving": "Saving...",
        "scheduleSections" : "Schedule sections",
        "scheduledSectionsLegend": "Sections",
        "scheduledSectionsText": "Choose which sections to schedule in TimeEdit:",
        "scheduledSectionsInfo": "NOTE! The options below are only for scheduling of sections in TimeEdit and should only be marked by course administrators. Sections in the course are found under Settings -> Sections.",
        "scheduledGroupsHeader": "Scheduled groups",
        "scheduledGroupsLegend": "Groups",
        "scheduledGroupsText": "Choose which groups to schedule in TimeEdit:",
        "scheduledGroupsInfo": "NOTE! The options below are only for scheduling of groups in TimeEdit and should only be marked by course administrators. Groups in the course are found under People.",
        "groupCategories": "Group categories",
        "scheduleGroups" : "Schedule groups",
        "schemaContextHeader": "Scheduled Course",
        "schemaContextLabel": "Yes, schedule in TimeEdit",
        "schemaContextCourseIsScheduledInTimeEdit": "This course is scheduled in TimeEdit.",
        "schemaContextText1": "This course is",
        "schemaContextText2": "currently not",
        "schemaContextText3": "scheduled in TimeEdit.",
        "schemaContextText": "Do you want to schedule this course in TimeEdit:",
        "groupMissingInCanvas": "The group is missing in Studium",
        "sectionMissingInCanvas": "The section is missing in Studium",
        "selectAll": "Select all",
        "show.help": "Help",
        "helpText": "Tick the groups and/or sections you want to schedule in TimeEdit. Keep in mind that it may take an hour or so before the groups/sections have been transferred to TimeEdit. To be able to distinguish groups/sections in TimeEdit, make sure that the names are not the same."
    }
}

export default messages;
